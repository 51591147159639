<template>
  <el-main>
    <el-form ref="form" :model="addForm" :rules="rules" class="add-form" label-width="110px">
      <el-form-item label="活动标题：" prop="title">
        <el-input v-model="addForm.title"></el-input>
      </el-form-item>
      <el-form-item label="活动时间：" prop="start_time">
        <el-date-picker v-model="addForm.start_time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label="抽奖次数：" prop="frequency">
        <div class="frequency">
          <span>频次</span>
          <el-select v-model="addForm.frequency_type" placeholder="请选择">
            <el-option label="日" :value="1"></el-option>
            <el-option label="周" :value="2"></el-option>
            <el-option label="月" :value="3"></el-option>
          </el-select>
          <el-input v-model="addForm.frequency">
            <template slot="append">次</template>
          </el-input>
        </div>
      </el-form-item>
      <el-form-item label="现金奖励：">
        <div>
          <el-switch v-model="addForm.is_cash" :active-value="1" :inactive-value="0"></el-switch>
          <el-button style="margin-left: 10px" class="el-icon-plus" type="text" size="small" @click="addReward(1)">添加现金</el-button>
        </div>
        <template v-if="addForm.is_cash">
          <el-table v-if="addForm.cash_reward.length" :data="addForm.cash_reward" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <el-table-column prop="name" label="现金奖励名称" align="center"></el-table-column>
            <el-table-column prop="image" label="奖励图片" align="center">
              <template v-slot="{ row }">
                <el-image style="width: 100px; height: 50px" fit="cover" :src="row.image"></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="probability" label="中奖概率" align="center">
              <template v-slot="{ row }">{{ row.probability }}%</template>
            </el-table-column>
            <el-table-column prop="num" label="每人总计中奖次数" align="center">
              <template v-slot="{ row }">{{ row.num || '--' }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right">
              <template v-slot="{ row, $index }">
                <el-button @click="editReward(row, 1, $index)" type="text">编辑</el-button>
                <el-button @click="delReward($index, 1)" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-form-item label="开启冷却：">
            <el-switch v-model="addForm.is_cash_cold" :active-value="1" :inactive-value="0"></el-switch>
            <span style="margin-left: 10px">开启后，单个用户每次中此奖池的奖励后，xx次/小时/天内不获得此奖池奖励</span>
          </el-form-item>
          <el-form-item style="margin-bottom: 10px" label="中奖冷却：" prop="is_cash_cold_times" v-if="addForm.is_cash_cold">
            <div class="cooling">
              <el-input v-model="addForm.is_cash_cold_times"></el-input>
              <el-select v-model="addForm.is_cash_cold_unit" placeholder="请选择">
                <el-option label="次" :value="1"></el-option>
                <el-option label="小时" :value="2"></el-option>
                <el-option label="天" :value="3"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="总金额限制：">
            <el-switch v-model="addForm.is_cash_total" :active-value="1" :inactive-value="0"></el-switch>
            <span style="margin-left: 10px">开启后，奖励金额超过下面总数，所有用户都不获得此奖池奖励</span>
          </el-form-item>
          <el-form-item label="总金额：" prop="is_cash_total_money" v-if="addForm.is_cash_total">
            <el-input v-model="addForm.is_cash_total_money">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </template>
      </el-form-item>
      <el-form-item label="积分奖励：">
        <div>
          <el-switch v-model="addForm.is_integral" :active-value="1" :inactive-value="0"></el-switch>
          <el-button style="margin-left: 10px" class="el-icon-plus" type="text" size="small" @click="addReward(2)">添加积分</el-button>
        </div>
        <template v-if="addForm.is_integral">
          <el-table v-if="addForm.integral_reward.length" :data="addForm.integral_reward" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <el-table-column prop="name" label="积分奖励名称" align="center"></el-table-column>
            <el-table-column prop="image" label="奖励图片" align="center">
              <template v-slot="{ row }">
                <el-image style="width: 100px; height: 50px" fit="cover" :src="row.image"></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="probability" label="中奖概率" align="center">
              <template v-slot="{ row }">{{ row.probability }}%</template>
            </el-table-column>
            <el-table-column prop="num" label="每人总计中奖次数" align="center">
              <template v-slot="{ row }">{{ row.num || '--' }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right">
              <template v-slot="{ row, $index }">
                <el-button @click="editReward(row, 2, $index)" type="text">编辑</el-button>
                <el-button @click="delReward($index, 2)" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-form-item label="开启冷却：">
            <el-switch v-model="addForm.is_integral_cold" :active-value="1" :inactive-value="0"></el-switch>
            <span style="margin-left: 10px">开启后，单个用户每次中此奖池的奖励后，xx次/小时/天内不获得此奖池奖励</span>
          </el-form-item>
          <el-form-item style="margin-bottom: 10px" label="中奖冷却：" prop="is_integral_cold_times" v-if="addForm.is_integral_cold">
            <div class="cooling">
              <el-input v-model="addForm.is_integral_cold_times"></el-input>
              <el-select v-model="addForm.is_integral_cold_unit" placeholder="请选择">
                <el-option label="次" :value="1"></el-option>
                <el-option label="小时" :value="2"></el-option>
                <el-option label="天" :value="3"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="总积分限制：">
            <el-switch v-model="addForm.is_integral_total" :active-value="1" :inactive-value="0"></el-switch>
            <span style="margin-left: 10px">开启后，奖励积分超过下面总数，所有用户都不获得此奖池奖励</span>
          </el-form-item>
          <el-form-item label="总积分：" prop="is_integral_total_money" v-if="addForm.is_integral_total">
            <el-input v-model="addForm.is_integral_total_money"></el-input>
          </el-form-item>
        </template>
      </el-form-item>
      <el-form-item label="不中奖文案：" prop="no_price_name">
        <el-input v-model="addForm.no_price_name"></el-input>
      </el-form-item>
      <el-form-item label="不中奖图片：" prop="no_price_image">
        <ReadyUploadSource @getSource="val => (addForm.no_price_image = val.path)" :path="addForm.no_price_image" @removeThis="() => (addForm.no_price_image = '')"></ReadyUploadSource>
      </el-form-item>
    </el-form>
    <el-dialog :title="add_reward_type == 1 ? '现金奖励' : '积分奖励'" :visible.sync="showAddReward" width="650px">
      <el-form ref="rewardForm" :model="rewardForm" :rules="rewardRules" label-width="140px">
        <el-form-item :label="add_reward_type == 1 ? '现金奖励名称：' : '积分奖励名称：'" prop="name">
          <el-input v-model="rewardForm.name"></el-input>
        </el-form-item>
        <el-form-item label="奖励图片：" prop="image">
          <ReadyUploadSource @getSource="val => (rewardForm.image = val.path)" :path="rewardForm.image" @removeThis="() => (rewardForm.image = '')"></ReadyUploadSource>
        </el-form-item>
        <el-form-item label="中奖概率：" prop="probability">
          <el-input v-model="rewardForm.probability">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="每人总计中奖次数：">
          <el-input v-model="rewardForm.num"></el-input>
          <p style="color: #999">不添加表示不限制</p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddReward = !1">取 消</el-button>
        <el-button type="primary" @click="confirmAddReward">确 定</el-button>
      </span>
    </el-dialog>
    <Preservation @preservation="confirmAdd">
        <router-link to="/extension/gatherTweeters/luckyDraw" slot="return" style="margin-right: 10px">
          <el-button>返回</el-button>
        </router-link>
    </Preservation>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
export default {
  components: {
    ReadyUploadSource,
    Preservation,
  },
  data() {
    return {
      addForm: {
        title: '',
        start_time: '',
        end_time: '',
        frequency: '',
        frequency_type: 1,
        is_cash: 1,
        cash_reward: [],
        is_cash_cold: 1,
        is_cash_cold_times: '',
        is_cash_cold_unit: 1,
        is_cash_total: 1,
        is_cash_total_money: '',
        is_integral: 1,
        integral_reward: [],
        is_integral_cold: 1,
        is_integral_cold_times: '',
        is_integral_cold_unit: 1,
        is_integral_total: 1,
        is_integral_total_money: '',
        no_price_name: '',
        no_price_image: '',
      },
      rewardForm: {
        name: '',
        image: '',
        probability: '',
        num: '',
      },
      rules: {
        title: { required: true, message: '请填写活动标题', trigger: 'blur' },
        start_time: { required: true, message: '请添加活动时间', trigger: 'blur' },
        frequency: { required: true, message: '请填写抽奖次数', trigger: 'blur' },
        is_cash_cold_times: { required: true, message: '请填写中奖冷却', trigger: 'blur' },
        is_cash_total_money: { required: true, message: '请填写总金额', trigger: 'blur' },
        is_integral_cold_times: { required: true, message: '请填写中奖冷却', trigger: 'blur' },
        is_integral_total_money: { required: true, message: '请填写总积分', trigger: 'blur' },
        no_price_name: { required: true, message: '请填写不中奖文案', trigger: 'blur' },
        no_price_image: { required: true, message: '请添加不中奖图片', trigger: 'blur' },
      },
      rewardRules: {
        name: { required: true, message: '请填写奖励名称', trigger: 'blur' },
        image: { required: true, message: '请添加奖励图片', trigger: 'blur' },
        probability: { required: true, message: '请填写中奖概率', trigger: 'blur' },
      },
      id: '',
      showAddReward: !1,
      add_reward_type: 1,
      is_edit_reward: !1,
      rewardIndex: 0,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      this.$axios
        .post(this.$api.serviceProvider.luckyDrawInfo, {
          id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            let info = res.result;
            let addForm = this.addForm;
            addForm.title = info.title;
            addForm.frequency = info.frequency;
            addForm.frequency_type = info.frequency_type;
            addForm.is_cash = info.is_cash;
            addForm.is_integral = info.is_integral;
            addForm.no_price_name = info.no_price_name;
            addForm.no_price_image = info.no_price_image;
            addForm.start_time = [info.start_time * 1000, info.end_time * 1000];
            if (info.is_cash) {
              addForm.cash_reward = info.cash_reward_info;
              addForm.is_cash_cold = info.is_cash_cold;
              addForm.is_cash_cold_times = info.is_cash_cold_times;
              addForm.is_cash_cold_unit = info.is_cash_cold_unit;
              addForm.is_cash_total = info.is_cash_total;
              addForm.is_cash_total_money = info.is_cash_total_money;
            }
            if (info.is_integral) {
              addForm.integral_reward = info.integral_reward_info;
              addForm.is_integral_cold = info.is_integral_cold;
              addForm.is_integral_cold_times = info.is_integral_cold_times;
              addForm.is_integral_cold_unit = info.is_integral_cold_unit;
              addForm.is_integral_total = info.is_integral_total;
              addForm.is_integral_total_money = info.is_integral_total_money;
            }
          }
        });
    },
    editReward(row, type, index) {
      this.is_edit_reward = !0;
      this.add_reward_type = type;
      this.rewardIndex = index;
      this.rewardForm = row;
      this.showAddReward = !0;
    },
    delReward(index, type) {
      type == 1 ? this.addForm.cash_reward.splice(index, 1) : this.addForm.integral_reward.splice(index, 1);
    },
    addReward(type) {
      this.add_reward_type = type;
      this.showAddReward = !0;
      this.is_edit_reward = !1;
      this.rewardForm = {
        name: '',
        image: '',
        probability: '',
        num: '',
      };
    },
    confirmAddReward() {
      this.$refs.rewardForm.validate(valid => {
        if (valid) {
          if (this.add_reward_type == 1) {
            this.is_edit_reward ? (this.addForm.cash_reward[this.rewardIndex] = this.rewardForm) : this.addForm.cash_reward.push(this.rewardForm);
          } else {
            this.is_edit_reward ? (this.addForm.integral_reward[this.rewardIndex] = this.rewardForm) : this.addForm.integral_reward.push(this.rewardForm);
          }
          this.showAddReward = !1;
        }
      });
    },
    confirmAdd() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let addForm = this.addForm;
          let data = {
            title: addForm.title,
            frequency: addForm.frequency,
            frequency_type: addForm.frequency_type,
            is_cash: addForm.is_cash,
            is_integral: addForm.is_integral,
            no_price_name: addForm.no_price_name,
            no_price_image: addForm.no_price_image,
          };
          data.end_time = addForm.start_time[1] / 1000;
          data.start_time = addForm.start_time[0] / 1000;
          if(this.id) data.id = this.id;
          if (data.is_cash) {
            if (!addForm.cash_reward.length) return this.$message.warning('请添加现金奖励');
            data.cash_reward = addForm.cash_reward;
            data.is_cash_cold = addForm.is_cash_cold;
            data.is_cash_cold_times = addForm.is_cash_cold_times;
            data.is_cash_cold_unit = addForm.is_cash_cold_unit;
            data.is_cash_total = addForm.is_cash_total;
            data.is_cash_total_money = addForm.is_cash_total_money;
          }
          if (data.is_integral) {
            if (!addForm.integral_reward.length) return this.$message.warning('请添加积分奖励');
            data.integral_reward = addForm.integral_reward;
            data.is_integral_cold = addForm.is_integral_cold;
            data.is_integral_cold_times = addForm.is_integral_cold_times;
            data.is_integral_cold_unit = addForm.is_integral_cold_unit;
            data.is_integral_total = addForm.is_integral_total;
            data.is_integral_total_money = addForm.is_integral_total_money;
          }
          this.$axios.post(this.id ? this.$api.serviceProvider.editLuckyDraw : this.$api.serviceProvider.addLuckyDraw, data).then(res => {
            if (res.code == 0) {
              this.$message.success(this.id ? '编辑成功' : '保存成功');
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  height: 100%;
  padding-bottom: 60px;
  .add-form {
    width: 800px;
    .frequency {
      display: flex;
      align-items: center;
      span {
        margin-right: 15px;
      }
      .el-select {
        width: 200px;
        margin-right: 10px;
      }
      .el-input {
        flex: 1;
      }
    }
    .cooling {
      display: flex;
      .el-input {
        width: 200px;
        margin-right: 10px;
      }
      .el-select {
        flex: 1;
      }
    }
  }
}
</style>